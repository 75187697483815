import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'
import { isEmptyOrNil } from 'toolbox/account'
import { lastRefresh } from 'state/slices/ui/dash'

export const fetchAllBrokersHistory = createAsyncThunk(
  'fetchAllBrokersHistory',
  async (): Promise<{ [key: types.NetworkAddress]: types.BrokerHistoryWithoutEvents }> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    lastRefresh()

    const hist = await sdk.fetcher.fetchAllBrokersHistory()
    return hist
  }
)
