import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchActiveUserHistory } from 'state/thunks/fetchActiveUserHistory'
import { types } from '@concordia/super-sdk'
import { scaleDown } from 'toolbox/format'

const initialState = initLoadable<{ [key: types.NetworkAddress]: types.UserHistory }>({})

export const activeUserHistory = createSlice({
  name: 'activeUserHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActiveUserHistory.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchActiveUserHistory.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default activeUserHistory.reducer
export const selectActiveUserHistory = (s: RootState) => s.app.activeUserHistory.value
export const selectActiveUserHistoryBusy = (s: RootState) =>
  s.app.activeUserHistory.status === 'busy'
export const selectActiveUserHistoryLoaded = (s: RootState) => s.app.activeUserHistory.loadedOnce

export const selectActiveUserTransactionHistory = createSelector(
  [selectActiveUserHistoryLoaded, selectActiveUserHistory],
  (loaded, history) => {
    if (!loaded) {
      return []
    }
    const events: types.TransactionHistory[] = Object.values(history).reduce(
      (acc, val) => acc.concat(val.events),
      []
    )
    return events
  }
)

export const selectLendTransactions = createSelector(
  [selectActiveUserTransactionHistory],
  (history) => {
    if (isEmptyOrNil(history)) {
      return []
    }
    return history.filter((tx) => tx.txType === 'lend')
  }
)

export const selectBorrowTransactions = createSelector(
  [selectActiveUserTransactionHistory],
  (history) => {
    if (isEmptyOrNil(history)) {
      return []
    }
    return history.filter((tx) => tx.txType === 'borrow')
  }
)
