import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchAllUsersHistory } from 'state/thunks/fetchAllUsersHistory'
import { types } from '@concordia/super-sdk'

const initialState = initLoadable<{ [key: types.NetworkAddress]: types.UserHistory }>({})

export const allUsersHistory = createSlice({
  name: 'allUsersHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsersHistory.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchAllUsersHistory.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default allUsersHistory.reducer
export const selectAllUsersHistory = (s: RootState) => s.app.allUsersHistory.value
export const selectAllUsersHistoryBusy = (s: RootState) => s.app.allUsersHistory.status === 'busy'
export const selectAllUsersHistoryLoaded = (s: RootState) => s.app.allUsersHistory.loadedOnce

export const selectAllUserTransactionHistory = createSelector(
  [selectAllUsersHistory],
  (histories) => {
    if (isEmptyOrNil(histories)) {
      return []
    }
    const events: types.TransactionHistory[] = Object.values(histories).reduce(
      (acc, val) => acc.concat(val.events),
      []
    )
    return events
  }
)
