import { timeTabs } from '../../state/slices/app/allBrokersHistory'

export interface TimeFilterProps {
  activeTab: string
  setActiveTab: (label: string) => void
  timeTabsOther?: { label: string; days: number }[]
  extraFormat?: string
}

const TimeFilterTabs = ({
  activeTab,
  setActiveTab,
  timeTabsOther,
  extraFormat
}: TimeFilterProps) => {
  const handleTabClick = (label) => {
    setActiveTab(label)
  }

  const useTimeTabs = timeTabsOther ? timeTabsOther : timeTabs

  return (
    <div className={`time-tabs ${extraFormat}`}>
      {useTimeTabs.map((tab) => (
        <button
          key={tab.label}
          className={`${activeTab === tab.label ? 'isActive' : ''}`}
          onClick={() => handleTabClick(tab.label)}>
          {tab.label}
        </button>
      ))}
    </div>
  )
}

export default TimeFilterTabs
