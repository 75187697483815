import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { useAppSelector } from 'state/hooks'
import {
  selectActiveUserHistoryLoaded,
  selectBorrowTransactions,
  selectLendTransactions
} from 'state/slices/app/activeUserHistory'

import { commaFloor, oneDecimal } from 'toolbox/format'
import { Leaderboard } from 'components/points/Leaderboard'
import {
  selectNextMulti,
  selectNextThreshold,
  selectNextTier,
  selectPointsTotalsLoaded,
  selectTier,
  selectTopTenDailyRanks,
  selectUserPoints
} from 'state/slices/app/points'
import { Referral } from 'components/points/Referral'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchCode } from 'state/fetch'
import { useEffect } from 'react'
import { Tooltip } from 'components/common/Tooltip'
import { selectIsActiveReferralMulti } from 'state/slices/user/referralsByUser'

export function Points() {
  const loaded = useAppSelector(selectActiveUserHistoryLoaded)
  const testnetLendTx = useAppSelector(selectLendTransactions)
  const testnetBorrowTx = useAppSelector(selectBorrowTransactions)
  const { account, connected } = useWallet()
  const address = account?.address || ''
  const pointsTotalsLoaded = useAppSelector(selectPointsTotalsLoaded)
  const topTenDaily = useAppSelector(selectTopTenDailyRanks)
  const { isMulti, multiDays } = useAppSelector(selectIsActiveReferralMulti)
  const lendTxLength = testnetLendTx.length >= 10 ? 10 : testnetLendTx.length
  const borrowTxLength = testnetBorrowTx.length >= 10 ? 10 : testnetBorrowTx.length

  const networkShort = process.env.REACT_APP_APTOS_NETWORK_SHORT

  const isTestnet = networkShort === 'testnet'
  const isLoaded = isTestnet ? loaded : pointsTotalsLoaded
  const userPoints = useAppSelector((state) => selectUserPoints(state, address))
  const totalPointsDisplay =
    isLoaded && connected
      ? isTestnet
        ? (lendTxLength + borrowTxLength) * 200
        : commaFloor(userPoints.runningtotalpoints)
      : '--'

  const captions = isTestnet
    ? [
        // '* Updated at 0:00 UTC every day',
        '* Points are earned based on protocol activity:',
        '200 Points per lend transaction, max 10 transactions per wallet',
        '200 Points per borrow transaction, max 10 transactions per wallet'
      ]
    : [
        // '* Updated at 0:00 UTC every day',
        '* Points are earned based on protocol activity:',
        '1 Point per dollar lent per day',
        '2 Points per dollar borrowed per day'
      ]

  const captionsDisplay = captions.map((c, i) => (
    <p key={i} className="points-caption">
      {c}
    </p>
  ))

  const multiplierDisplay =
    isLoaded && connected
      ? isTestnet
        ? oneDecimal(1) + 'x'
        : oneDecimal(isMulti ? 2 : userPoints.multiplier) + 'x'
      : '--'

  const userTierInitial = useAppSelector((s) => selectTier(s, address))
  const userDailyPoints = userPoints.dailypoints
  const userTier = isMulti ? 1 : userTierInitial

  const tierDisplay = isLoaded && connected ? (isTestnet ? 'Tier 4' : `Tier ${userTier}`) : '--'
  const dailyActivePoints =
    isLoaded && connected ? (isTestnet ? 0 : commaFloor(userDailyPoints)) : '--'
  const nextTier = useAppSelector((s) => selectNextTier(s, address))
  const nextMulti = useAppSelector((s) => selectNextMulti(s, address))
  const nextTierXDisplay =
    isLoaded && connected ? (isTestnet ? 0 : `${oneDecimal(nextMulti)}x`) : '--'
  const nextTierDisplay =
    isLoaded && connected ? (
      isTestnet ? (
        0
      ) : userTier === 1 ? (
        <p className="nt-label">Congratulations, you have reached the highest tier</p>
      ) : (
        <>
          <p className="nt-label">{`Next tier: Tier ${nextTier}`}</p>
          <div className="coin smush">{nextTierXDisplay}</div>
        </>
      )
    ) : (
      <p className="nt-label">--</p>
    )
  const nextTierThreshold = useAppSelector((s) => selectNextThreshold(s, address))
  const nextTierThresholdDisplay =
    isLoaded && connected ? (
      isTestnet ? (
        0
      ) : userTier === 1 ? (
        <p className="tier-at">#1 user: {commaFloor(topTenDaily[0].dailypoints)}</p>
      ) : (
        <>
          <p className="tier-at">Next tier at: {commaFloor(nextTierThreshold)}</p>
        </>
      )
    ) : (
      <p className="tier-at">--</p>
    )

  useEffect(() => {
    if (!isEmptyOrNil(address)) {
      fetchCode(address)
    }
  }, [address])

  const progressValue = userTier === 1 ? userDailyPoints : Number(dailyActivePoints)
  const progressMax = userTier === 1 ? topTenDaily[0]?.dailypoints : nextTierThreshold

  const percentage = (progressValue / progressMax) * 100
  const labelPosition = `calc(${percentage}% + (${8 - percentage * 0.16}px))`

  return (
    <div className="points">
      <h1>Points</h1>
      <div className="points-card">
        <div className="total-points-row">
          <div className="total-points">
            <p className="big-points">{totalPointsDisplay}</p>
            <Tooltip
              label="Total points"
              value="Cumulative points total for all days, categories, with multipliers"
            />
          </div>
          <div className="points-row-details">
            Earn 1 point per dollar lent and 2 points per dollar borrowed daily, multiplied by the
            daily multiplier.
          </div>
        </div>
        <div className="user-points">
          <div className="rec large">
            <div className="rec-dp-content">
              <h3>Daily Points multiplier</h3>
              <div className="coin">
                <div className="multi">{multiplierDisplay}</div>
                <div className="tier">{tierDisplay}</div>
              </div>
              <div className="prog-data-rows">
                <div className="top-row">
                  <div className="your-points">
                    <p className="yp-label"> Your daily activity points:</p>
                    <p className="yp-value">{dailyActivePoints}</p>
                    <Tooltip label={''} value={'Daily points earned from protocol activity'} />
                  </div>
                  <div className="next-tier">{nextTierDisplay}</div>
                </div>
                <div className="progress-container">
                  <progress value={progressValue || 0} max={progressMax || 0} />
                  <div className="progress-label" style={{ left: labelPosition }}>
                    {commaFloor(progressValue)}
                  </div>
                </div>
                <div className="bot-row">{nextTierThresholdDisplay}</div>
              </div>
            </div>
            {!!isMulti && (
              <div className="ref-multi">
                <p>Your referral code gives you the max tier for {multiDays} more days.</p>
              </div>
            )}
          </div>
        </div>
        <Referral userPoints={userPoints} />
        {!isTestnet && <Leaderboard />}
        <hr className="hr" />

        <div className="points-captions">{captionsDisplay}</div>
      </div>
    </div>
  )
}
