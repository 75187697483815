import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchAllBrokersHistory } from 'state/thunks/fetchAllBrokersHistory'
import { types } from '@concordia/super-sdk'
import {
  getBrokerByName,
  getPriceFromName,
  selectBrokersWithMeta,
  selectPricesFromBrokers
} from './brokers'
import { scaleDown } from 'toolbox/format'

const initialState = initLoadable<{
  [key: types.NetworkAddress]: types.BrokerHistoryWithoutEvents
}>({})

export const allBrokersHistory = createSlice({
  name: 'allBrokersHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBrokersHistory.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchAllBrokersHistory.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default allBrokersHistory.reducer
export const selectAllBrokersHistory = (s: RootState) => s.app.allBrokersHistory.value
export const selectAllBrokersHistoryBusy = (s: RootState) =>
  s.app.allBrokersHistory.status === 'busy'
export const selectAllBrokersHistoryLoaded = (s: RootState) => s.app.allBrokersHistory.loadedOnce

export const getBrokerHistoryByName = (
  name: string,
  histories: { [key: types.NetworkAddress]: types.BrokerHistoryWithoutEvents }
) => {
  if (isEmptyOrNil(histories)) {
    return null
  }
  return histories[name]
}

export const selectBrokerUtilizationHistory = createSelector(
  [selectAllBrokersHistory, selectPricesFromBrokers, selectBrokersWithMeta, (state, days) => days],
  (allHistory, prices, brokers, days) => {
    if (isEmptyOrNil(allHistory) || isEmptyOrNil(prices) || isEmptyOrNil(brokers)) {
      return []
    }
    //get instrument names to iterate through
    const instrumentNames = Object.keys(allHistory)
    //set up object for totals by name
    const totalsByName = {}
    //iterate through instrument names
    instrumentNames.forEach((name) => {
      //get utilization history for single broker
      const utilHistoryByName = allHistory[name].utilizationHistory
      //set up array for total dollar values
      const totals = []

      //get metadata for calculations
      const price = getPriceFromName(name, prices)
      const broker = getBrokerByName(brokers, name)
      const lendDecimals = broker?.depositNote.decimals
      const borrowDecimals = broker?.loanNote.decimals

      for (let i = 0; i < days; i++) {
        //get history pre grouped into one day bucket
        const dailyHistoryByEndDay = getDailyUtilizationsGroupedByDay(utilHistoryByName, i - 1)

        //iterate through daily history
        const dayKeys = Object.keys(dailyHistoryByEndDay)
        dayKeys.forEach((dk) => {
          //get singular day history
          const dailyHistory = dailyHistoryByEndDay[dk]
          //calcualte totals
          const totalLend = dailyHistory.reduce(
            (acc, val) => acc + scaleDown(val.totalLend, lendDecimals) * price,
            0
          )
          const totalBorrow = dailyHistory.reduce(
            (acc, val) => acc + scaleDown(val.totalBorrow, borrowDecimals) * price,
            0
          )
          //push totals with timestamp
          const length = dailyHistory.length
          totals.push({
            timestamp: dk,
            totalLend: totalLend / length || 0,
            totalBorrow: totalBorrow / length || 0
          })
        })
      }
      //AFTER LOOPING: set totals for single broker on object by name
      totalsByName[name] = totals
    })
    //combine all totals into one array
    const totalsNameKeys = Object.keys(totalsByName)
    const combinedTotalsArray = []
    totalsNameKeys.forEach((key) => {
      combinedTotalsArray.push(...totalsByName[key])
    })
    //group combined totals by day and sum
    const totalValuesByDay = {}
    combinedTotalsArray.forEach(({ timestamp, totalBorrow, totalLend }) => {
      if (!totalValuesByDay[timestamp]) {
        totalValuesByDay[timestamp] = { totalBorrow: 0, totalLend: 0 }
      }
      totalValuesByDay[timestamp].timestamp = timestamp
      totalValuesByDay[timestamp].totalBorrow += totalBorrow
      totalValuesByDay[timestamp].totalLend += totalLend
    })

    //filter out zero values and start array when totalLend > 0
    const filteredValues = Object.values(totalValuesByDay)
      .filter(({ totalLend }) => totalLend > 0)
      .sort((a: any, b: any) => a.timestamp - b.timestamp)

    //return filtered array of dollar value totals for all brokers by day
    return filteredValues.reverse()
  }
)

export const getDailyUtilizationsGroupedByDay = (
  utilizationHistories: types.UtilizationHistory[],
  dayOffset: number
): { [endOfDay: number]: types.UtilizationHistory[] } => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  //offset by i to get single days
  const endOfDay = now.getTime() - dayOffset * 86400000
  const startOfDay = endOfDay - 86400000

  //filter for single day windows
  const filteredHistories = utilizationHistories.filter((history) => {
    const timestamp = history.timestamp * 1000
    return timestamp > startOfDay && timestamp < endOfDay
  })

  //return result object keyed by end of day timestamp
  const result = {
    [endOfDay]: filteredHistories
  }

  return result
}

export const selectBrokerUtilizationHistoryByName = createSelector(
  [
    selectAllBrokersHistory,
    selectPricesFromBrokers,
    selectBrokersWithMeta,
    (state, name) => name,
    (state, name, numberOfDays) => numberOfDays
  ],
  (allBrokersHistory, prices, brokers, name, numberOfDays) => {
    if (isEmptyOrNil(allBrokersHistory) || isEmptyOrNil(prices) || isEmptyOrNil(brokers)) {
      return []
    }
    const utilizationHistory = getBrokerHistoryByName(name, allBrokersHistory)?.utilizationHistory
    if (isEmptyOrNil(utilizationHistory)) {
      return []
    }
    const broker = getBrokerByName(brokers, name)
    const lendPrice = getPriceFromName(broker.depositNote.name, prices)
    const borrowPrice = getPriceFromName(broker.loanNote.name, prices)
    const lendDecimals = broker?.depositNote.decimals
    const borrowDecimals = broker?.loanNote.decimals

    const totals = []
    for (let i = 0; i < numberOfDays; i++) {
      const dailyUtilization = getDailyUtilizationsGroupedByDay(utilizationHistory, i - 1)
      const dayKeys = Object.keys(dailyUtilization)
      dayKeys.forEach((dk) => {
        const dailyHistory = dailyUtilization[dk]

        const totalLend = dailyHistory.reduce(
          (acc, val) => acc + scaleDown(val.totalLend, lendDecimals) * lendPrice,
          0
        )
        const totalBorrow = dailyHistory.reduce(
          (acc, val) => acc + scaleDown(val.totalBorrow, borrowDecimals) * borrowPrice,
          0
        )
        const length = dailyHistory.length

        const averageUtil = calculateAverageUtil(dailyHistory)
        totals.push({
          timestamp: dk,
          totalLend: totalLend / length || 0,
          totalBorrow: totalBorrow / length || 0,
          util: averageUtil
        })
      })
    }
    return totals
  }
)

function calculateAverageUtil(data) {
  // Check if data is not empty
  if (!data || data.length === 0) {
    return 0 // Return 0 for empty data to avoid division by zero
  }

  // Sum up all util values
  const totalUtil = data.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.util
  }, 0)

  // Calculate average
  const averageUtil = totalUtil / data.length

  return averageUtil
}

function calculateDaysFromStartOfYear(): number {
  // Get today's date
  const today = new Date()

  // Get the start of the year
  const startOfYear = new Date(today.getFullYear(), 0, 1)

  // Calculate the difference in milliseconds
  const diffInMs = today.getTime() - startOfYear.getTime()

  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

  return diffInDays
}

// Tabs data
export const timeTabs = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '1Y', days: 365 },
  { label: 'YTD', days: calculateDaysFromStartOfYear() }
]

export const timeTabsPrice = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '1Y', days: 365 },
  { label: 'YTD', days: calculateDaysFromStartOfYear() }
]
