import {
  RiskEquity,
  Broker,
  WalletBalances,
  WalletBalance,
  CoreTypes_EventTransaction,
  CoreTypes_FullEventTransactions,
  LiquidationHistoryItem,
  CoreTypes_BrokerHistory,
  PositionSuperCore
} from '@concordia/super-json-api-client'
import * as Gen from '@concordia/super-json-api-client'

export type SInstrument = Gen.CoreTypes_PricedInstrument
export type SRisk = RiskEquity
export type NameBalanceMap = { [key: string]: number }
export type SWalletBalance = WalletBalance
export type SWalletBalances = WalletBalances
export type TransactionArgs = Gen.TransactionRequest
export type TransactionResponse = Gen.PacketResponse
export type SBalanceHistory = Gen.CoreTypes_PortfolioHistoryByDateTotals
export type SReferral = Gen.CoreTypes_Referal
export type SReferralsByUser = Gen.CoreTypes_ReferralsByUser
export type SFlashLoanStrategy = Gen.CoreTypes_FlashLoanStrategy
export type SFlashLoanStrategies = Gen.CoreTypes_FlashLoanStrategy[]
export type SFeatureToggles = Gen.FeatureToggles
export type OpenMultiplyVaultArgs = Gen.CoreTypes_LendAndBorrowRequestV1
export type CloseMultiplyVaultArgs = Gen.CoreTypes_RepayAndRedeemRequestV1
export type SPortfolioState = Gen.PortfolioState

export interface TotalsWithMulti extends Gen.CoreTypes_PointsHistoryTotals {
  multiplier: number
  ranking: number
  ranking_total: number
}

export type SPointsHistory = Gen.CoreTypes_PointsHistoryTotals
export type SPointsTotals = Gen.CoreTypes_PointsHistoryTotals[]

export type SBroker = Omit<
  Broker,
  'scaledAvailableLiquidityUnderlying' | 'scaledTotalBorrowedUnderlying'
> & {
  scaledAvailableLiquidityUnderlying: number
  scaledTotalBorrowedUnderlying: number
  interestFeeRate: number
}

export type SPosition = Omit<PositionSuperCore, 'scaledAmount'> & {
  scaledAmount: number
}

export type SMaxBorrow = {
  [key: string]: string
}

export interface PortfolioHistoryDaily {
  collateralDailyDollars: number
  liabilityDailyDollars: number
  dailyPoints: number
  runningTotalValue: number
  runningTotalPoints: number
}

export interface PortfolioHistoryByDate {
  [key: string]: PortfolioHistoryDaily
}

export interface SPortfolio {
  id: string
  collaterals: SPosition[]
  liabilities: SPosition[]
  risk: SRisk
  maxBorrow: SMaxBorrow
}

export type SUserStrategy = Gen.CoreTypes_UserStrategy

export interface HistoricalPrice {
  timestamp: number
  usd: string
}

export interface HistoricalPrices {
  [key: string]: HistoricalPrice[]
}

export interface UtilizationHistory {
  timestamp: number
  totalLend: number
  totalBorrow: number
  util: number
}

export interface InterestRateHistory {
  timestamp: number
  lendRate: number
  borrowRate: number
}

export type NetworkAddress = string

export interface BrokerHistory {
  utilizationHistory: UtilizationHistory[]
  interestRateHistory: InterestRateHistory[]
  events: EventHistory[]
}

export interface BrokerHistoryWithoutEvents {
  utilizationHistory: UtilizationHistory[]
  interestRateHistory: InterestRateHistory[]
}

export interface UserHistory {
  events: EventHistory[]
}

export interface AllBrokersHistory {
  brokers: {
    [key: NetworkAddress]: BrokerHistory
  }
  user: {
    [key: NetworkAddress]: UserHistory
  }
}

export type TxType = 'borrow' | 'repay' | 'lend' | 'redeem' | 'liquidate'
export type UnixTimestamp = number

export interface TransactionHistory {
  txType: TxType
  time: UnixTimestamp
  amount: number
  brokerName: string
  userAddress: string
  version: string
}

export interface LiquidationHistory {
  txType: TxType
  time: UnixTimestamp
  collatAmount: number
  liabAmount: number
  brokerName: string
  userAddress: string
  version: string
}

export type EventHistory = TransactionHistory | LiquidationHistory

export interface BrokerHistoryData {
  [key: string]: {
    events: CoreTypes_FullEventTransactions[]
    history: CoreTypes_BrokerHistory[]
  }
}

export interface BrokerHistoryDataWithoutEvents {
  [key: string]: CoreTypes_BrokerHistory[]
}

export interface UserHistoryData {
  [key: string]: {
    events: CoreTypes_EventTransaction[]
  }
}

export function transformEvents(events: CoreTypes_FullEventTransactions[]): EventHistory[] {
  return events.map((e) => {
    const FULL_LENGTH = 66

    // const isTxEvent = typeof e === CoreTypes_EventTransaction
    if (e.action === 'liquidate') {
      const liqEvent: LiquidationHistoryItem = e as LiquidationHistoryItem
      return {
        txType: liqEvent.action as TxType,
        time: liqEvent.created,
        collatAmount: Number(liqEvent.collateral_amount),
        liabAmount: Number(liqEvent.liability_amount),
        brokerName: liqEvent.collateral_price_id,
        userAddress: liqEvent.liqee_address,
        version: liqEvent.transaction_version
      }
    } else {
      const event: CoreTypes_EventTransaction = e as CoreTypes_EventTransaction

      let userAddress = event.portfolio_address
      if (userAddress.length === FULL_LENGTH - 1) {
        const slicedAddress = userAddress.slice(2)
        const paddedAddress = '0' + slicedAddress
        userAddress = '0x' + paddedAddress
      }
      return {
        txType: event.action as TxType,
        time: event.created,
        amount: Number(event.amount),
        brokerName: event.broker_id,
        userAddress,
        version: event.transaction_version
      }
    }
  })
}
